import React, { ReactNode } from "react";

import HeaderResponsive from "./header";
import Footer from "./footer";

type Props = {
	children: ReactNode;
	title?: string;
	description?: string;
	preview?: boolean;
	openGraph?: any;
};

const variants = {
	hidden: { opacity: 0 },
	enter: { opacity: 1 },
	exit: { opacity: 0 },
};

const Layout = ({ title, description, children, preview, openGraph }: Props): JSX.Element => {
	return (
		<>
			<HeaderResponsive />
			<div className="max-w-full font-body">
				{children}
				<Footer />
			</div>
		</>
	);
};

export default Layout;
