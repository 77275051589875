import Link from "next/link";
import { ReactElement } from "react";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import { Logo } from "./header";

const Footer = () => {
	return (
		<footer className="bg-black py-8 mt-8">
			<div className="content-container px-4 xl:px-0">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
					<div className="space-y-4">
						<div className="w-[165px]">
							<Logo />
						</div>
						<p>Välkommen till VD Motor AB, din valda destination för högkvalitativa begagnade bilar.</p>
					</div>

					<div></div>
					<div>
						<p className="text-lg uppercase font-semibold">Länkar</p>
						<Link href="/garantier">Garanti & Service</Link>
					</div>
					<div>
						<p className="text-lg uppercase font-semibold">Kontakt</p>
						<div className="space-y-4">
							<div>
								<p className="my-1 font-bold">Växel</p>
								<Link href="tel:01160501" title="Telefonnummer" className="underline link">
									011-60 501
								</Link>
							</div>
							<div>
								<p className="my-1 font-bold">Email</p>
								<Link href="mailto:info@vdmotor.se" title="Email" className="underline link">
									info@vdmotor.se
								</Link>
							</div>
							<div>
								<p className="my-1 font-bold">Öppettider</p>{" "}
								<Link href="/kontakt" title="Öppettider" className="underline link">
									Mån-fre 10-18
									<br />
									Lör-Sön: 11-14
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col md:flex-row justify-center md:justify-between items-center mt-4 py-4 text-center md:text-left">
					<p className="text-accent">
						Copyright © {new Date().getFullYear()} All rights reserved | V&D Motor Sweden AB
					</p>

					<div className="flex flex-nowrap gap-2">
						<Link aria-label="Gå till Facebook sida" href="https://facebook.com/vdmotor.se">
							<FaFacebook size={22} />
						</Link>
						<Link aria-label="Gå till Instagram sida" href="https://instagram.com/vdmotorab">
							<FaInstagram size={22} />
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
