import Image from "next/image";
import Link from "next/link";
import { useMobileMenu } from "@lib/context/mobile-menu-context";
import { FaChevronRight } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Logo } from "@components/header";

const collapseItems = [
	{ label: "Startsidan", link: "/" },
	{ label: "Fordon i lager", link: "/fordon" },
	{ label: "Sälj ditt fordon", link: "/salj-ditt-fordon" },
	{ label: "Kontakt", link: "/kontakt" },
	{ label: "Om oss", link: "/om-oss" },
];

const MainMenu = () => {
	const { close } = useMobileMenu();

	return (
		<div className="flex flex-col flex-1">
			<div className="flex items-center gap-4 w-full border-b border-neutral px-4 text-accent">
				<div className="">
					<button className="btn btn-ghost" onClick={close}>
						<AiOutlineClose size={20} />
					</button>
				</div>
				<div className="py-2">
					<Logo />
				</div>
			</div>

			<div className="space-y-6 flex-1 flex flex-col justify-between p-6">
				<div className="flex flex-col flex-1">
					<ul className="flex flex-col gap-y-2">
						{collapseItems.map((menuItem) => {
							return (
								<li key={JSON.stringify(menuItem)} className="bg-neutral text-accent p-4">
									<Link href={menuItem.link}>
										<button
											className="flex items-center justify-between w-full text-base uppercase font-heading font-semibold"
											onClick={close}
										>
											<span className="sr-only">Till {menuItem.label}</span>
											<span>{menuItem.label}</span>
											<FaChevronRight />
										</button>
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default MainMenu;
